@media screen and (max-width: 600px) {
  .image-promo,
  .slider-promo {
    max-width: 74vw !important;
  }
}

.card-event .card-image {
  transition: transform 0.3s ease-in-out, box-shadow 0.1s ease;
}

.card-event:hover .card-image {
  transform: scale(1.1); /* Rotate and slightly scale the image */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover for depth */
}

.social-button-share img {
  transition: all 0.5s ease;
  border-radius: 50%;
}

.social-button-share:hover img {
  border: 1px solid white;
}
